


































































import { defineComponent, reactive, ref } from '@/plugins/composition';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import { required, rules } from '@/utils/validations';
import { storage } from '@/main';
import { useFormValidation } from '@/composables/useFormValidation';
import { useLoader } from '@/composables/useLoader';

type Form = { resetValidation: () => void };

export default defineComponent({
  name: 'ChangePassword',

  setup() {
    const data = reactive({
      password: '',
      currentPassword: '',
    });

    const { errors, mapErrors, valid } = useFormValidation(data);
    const { loading, withLoader } = useLoader();
    const { loading: sending, withLoader: withSender } = useLoader();

    const user = storage.auth.getters.user();
    const message = ref('');

    const resent = ref(false);
    const form = ref<Form | null>(null);

    const isPasswordVisible = ref(false);
    const isCurrentPasswordVisible = ref(false);

    const submit = async () =>
      withLoader(async () => {
        const response = await user
          .updatePassword(data.currentPassword, data.password)
          .catch((error) => {
            mapErrors(error);
          });

        if (response) {
          data.password = '';
          data.currentPassword = '';
          isPasswordVisible.value = false;
          isCurrentPasswordVisible.value = false;
          form.value?.resetValidation();
          message.value = 'Yor password has been reset.';
        }
      });

    return {
      data,
      errors,
      rules,
      required,
      valid,
      submit,
      loading,
      sending,
      resent,
      form,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      isPasswordVisible,
      isCurrentPasswordVisible,
      message,
    };
  },
});
